#HomeHeader{
  background: url(./images/mountains.jpg);
  background-size: cover;
  background-position: center;
  min-height: 1000px;
}

#ProjectHeader{
  background: url(./images/ComputerChip2.jpg);
  background-size: cover;
  background-position: center;
  min-height: 400px;
}

.section {
  padding-top: 4vw;
  padding-bottom: 4vw;
}

.brand-logo{
  font-size: 2.5rem !important;
}

.section-top{
  padding-top: 4vw;
}

.section-bottom{
  padding-bottom: 4vw;
}

.transform-up{
  transform: translate(0,-100%);
}

.image-spacer{
  margin: 10px 0;
}

#languages{
  word-break: keep-all;
  overflow: visible;
}

.resume{
  text-decoration: underline;
}

.icon-spacer{
  margin-right: 10px;
}

.email-icon{
  cursor: default !important;
}

.section-bottom-small{
  margin-bottom: 2vw;
}

.text-align-top{
  margin-top: 0;
}

.project-img{
  border: 12px ridge #666;
  /* max-width: 90%; */
}

.left-10-px{
  margin-left: 10px;
}

.top-0{
  margin-top: 0 !important;
}

#here:hover{
  text-decoration: underline;
}

@media screen and (max-width: 600px){

  #HomeHeader{
    min-height: 700px;
  }

  #ProjectHeader{
    min-height: 300px;
  }

  .parallax-container{
    max-height:250px;
  }

  .parallax-2{
    max-height: 150px; 
  }

  #ClickForProjects{
    font-size: 20px !important;
  }  
}

@media screen and (max-width: 446px) {
  nav{
    line-height: 45px !important;
    height: 95px !important;
  }
}

/* Scrollbar styles */
.hideScrollbar::-webkit-scrollbar{
  display: none;
}

.scrollbar{
  position: fixed;
  right: 0;
  top: 0;
  width: 25px;
  margin-right: 0;
  border: inset 13px solid #232943;
  box-shadow: inset 0 0 4px 2px rgba(0,0,0,0.5);
  border-radius: 10px;
  height: 100vh;
  z-index: 5;
}

.thumb{
  position: fixed;
  right: 0;
  height: 140px;
  width: 25px;
  background: linear-gradient(
    45deg,
    #06dee1,
    #79ff6c
  );
  border-radius: 10px;
}

.veil{
  position: fixed;
  right: 0;
  top: 0;
  height: 140px;
  width: 25px;
  background-color: transparent;
}

.veil:active{
  cursor: none;
}

/* End Scrollbar Styles */